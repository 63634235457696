import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {AuthState, IUser} from "./types";


const user = JSON.parse(localStorage.getItem("user") as string) ?? {
    first_name:'',
    last_name:'',
    email_verified_at:''
};
const token = JSON.parse(localStorage.getItem("token") as string) ?? ''

export const initialState: AuthState = {
    token: token,
    user:user,
    isLoading: false,
    error: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // todo : add your reducers to change and manipulate the state
        setAuthIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setAuthError(state, action: PayloadAction<string | null>) {
            state.error = action.payload;
        },
        setAuthUser(state , action: PayloadAction<IUser>){
            state.user = action.payload
        },
        setAuthToken(state , action: PayloadAction<string>){
            state.token = action.payload
        }
    }
})

export default authSlice.reducer;
