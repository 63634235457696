import CopySvg from "./CopySvg";
import "./style.css";

const ImportNFTAddress = () => {
  const tokenValue = "0x730dd2594a072b6f73a0c1a33031d5f2f4f95f8b";
  return (
    <section id="import-address" className="container page-section">
      <h4>
        IMPORT NFT
        <img
          className="nftx-x"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 ADDRESS
      </h4>
      <span
        onClick={() => navigator.clipboard.writeText(tokenValue)}
        className="token-value"
      >
        {tokenValue}
        <CopySvg />
      </span>
    </section>
  );
};

export default ImportNFTAddress;
