import "./style.css";

const JoinBanner = () => {
  return (
    <section id="join-banner">
      <img src="/assets/images/pages/about/banner.jpg" alt="Banner Image" />
    </section>
  );
};

export default JoinBanner;
