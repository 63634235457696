import "./style.css";
import { defaultfaqs } from "./data";
import { useState } from "react";

const FAQS = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="faqs-tabs">
      {defaultfaqs.map(({ id, title, text }) => {
        return (
          <article
            key={id}
            className={`faq-tab-${id} ${activeTab === id ? "active" : ""}`}
          >
            <h6 onClick={() => setActiveTab(id)}>{title}</h6>
            <p className="text-justified">{text}</p>
          </article>
        );
      })}
    </div>
  );
};

export default FAQS;
