import "./style.css";
import { useEffect, useState } from "react";
import { defaultNavigationData } from "./data";
import CircledButton from "../../common/PrimaryButton/PrimaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import handleChangeNetwork from "../../../utils/handleChangeNetwork";
import addrShortner from "../../../utils/addrShortner";
import {useAccount, useConnect, useDisconnect} from "wagmi";
import { InjectedConnector } from 'wagmi/connectors/injected'


const Header = () => {
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState(false);
  const [navigationArray, setNavigationArray] = useState(defaultNavigationData);

  const {connect} = useConnect({
    connector: new InjectedConnector(),
  })
  const { address:account } = useAccount()
  const { disconnect:deactivate } = useDisconnect()




  useEffect(() => {
    if (window.location.pathname == "/") {
      console.log("home page");
      navigate("/about");
    }
    window.scrollTo(0, 0);
  }, [useLocation()]);

  return (
    <section id="site-header">
      <div className="container">
        <img src="/assets/images/logo/svg-logo-white.svg" alt="NX7 Logo" />
        <div id="burger-icon" onClick={() => setActiveNav(!activeNav)}>
          <svg
            width="119"
            height="79"
            viewBox="0 0 119 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.58335 79C4.71807 79 3.15344 78.368 1.88944 77.104C0.625439 75.84 -0.00436611 74.2775 2.27797e-05 72.4166C2.27797e-05 70.5514 0.632023 68.9867 1.89602 67.7227C3.16002 66.4587 4.72246 65.8289 6.58335 65.8333H111.917C113.782 65.8333 115.347 66.4653 116.611 67.7293C117.875 68.9933 118.504 70.5557 118.5 72.4166C118.5 74.2819 117.868 75.8465 116.604 77.1105C115.34 78.3745 113.777 79.0044 111.917 79H6.58335ZM6.58335 46.0833C4.71807 46.0833 3.15344 45.4513 1.88944 44.1873C0.625439 42.9233 -0.00436611 41.3609 2.27797e-05 39.5C2.27797e-05 37.6347 0.632023 36.0701 1.89602 34.8061C3.16002 33.5421 4.72246 32.9123 6.58335 32.9167H111.917C113.782 32.9167 115.347 33.5487 116.611 34.8127C117.875 36.0767 118.504 37.6391 118.5 39.5C118.5 41.3653 117.868 42.9299 116.604 44.1939C115.34 45.4579 113.777 46.0877 111.917 46.0833H6.58335ZM6.58335 13.1667C4.71807 13.1667 3.15344 12.5347 1.88944 11.2707C0.625439 10.0067 -0.00436611 8.44424 2.27797e-05 6.58335C2.27797e-05 4.71807 0.632023 3.15344 1.89602 1.88944C3.16002 0.62544 4.72246 -0.00436611 6.58335 2.27796e-05H111.917C113.782 2.27796e-05 115.347 0.632023 116.611 1.89602C117.875 3.16002 118.504 4.72246 118.5 6.58335C118.5 8.44863 117.868 10.0133 116.604 11.2773C115.34 12.5413 113.777 13.1711 111.917 13.1667H6.58335Z"
              fill="white"
            />
          </svg>
        </div>
        <nav className={activeNav ? "active header-nav" : "header-nav"}>
          <ul>
            {navigationArray.map((menuItem) => {
              return menuItem.path !== "no-path" ? (
                <li
                  className={
                    window.location.pathname == menuItem.path
                      ? "nav-link active"
                      : "nav-link"
                  }
                  key={menuItem.id}
                  onClick={() => {
                    menuItem.external_link
                      ? (window.location.href = menuItem.path)
                      : navigate(menuItem.path);
                    setActiveNav(false);
                  }}
                >
                  {menuItem.title}
                </li>
              ) : (
                <li className="nav-text" key={menuItem.id}>
                  {menuItem.title}
                </li>
              );
            })}
            {account ? (
              <li>
                <CircledButton
                  text={`${addrShortner(account)}`}
                  onClick={deactivate}
                ></CircledButton>
              </li>
            ) : (
              <li>
                <CircledButton text="Connect Wallet" onClick={connect} />
              </li>
            )}
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default Header;
