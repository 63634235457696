import stakeSteps from "./data";
import "./style.css";

const StakeSteps = () => {
  return (
    <section id="how-to-stake" className="page-section">
      <div className="container">
        <h2 className="section-heading">How To Stake</h2>
        <ul>
          {stakeSteps.map((title, index) => {
            return (
              <li key={`stake_step_${index}`}>
                <h6>Step {index + 1}</h6>
                <h4>{title}</h4>
                <img
                  src={`assets/images/pages/stake/step-${index + 1}.jpg`}
                  alt={`Step ${index + 1}`}
                />
              </li>
            );
          })}
        
        </ul>
      </div>
    </section>
  );
};

export default StakeSteps;
