import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/common/PrimaryButton/PrimaryButton";
import "./style.css";

const OurStory = () => {
  const navigate = useNavigate();

  return (
    <section id="our-story" className="container page-section">
      <article>
        <h2 className="secondary-heading">
          The Story of NFT
          <img
            className="nftx-x"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7
        </h2>
        <p className="text-justified leading-8">
          NFT
          <img className="nftx-x" src="../assets/images/logo/nftx-x.png" />7 is
          an NFT collection that serves as proof of fractional debt. The project
          aims to invest the cashflow generated from mint sell-outs in scalp
          trading and pay rewards to NFT
          <img
            className="nftx-x"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 holders in the form of NX7 tokens, which have a fixed reward
          value. The token has a deflationary mechanism and a built-in volume
          that serves as the cashflow coming from reward payments.
        </p>
      </article>
      <div className="gif-container">
        <img
          style={{ borderRadius: "3%" }}
          src="assets/images/nfts/NFT-GIF.gif"
          alt="NFT GIF"
        />
        <PrimaryButton
          buttonClass="primary-button"
          text="mint"
          onClick={() => navigate("/mint")}
        />
      </div>
    </section>
  );
};

export default OurStory;
