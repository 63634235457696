import CreatedBy from "./CreatedBy/CreatedBy"
import GetInTouch from "./GetInTouch/GetInTouch"
import MeetOurTeam from "./MeetOurTeam/MeetOurTeam"

const Team = () => {
    return (
        <>
            <MeetOurTeam />
            <GetInTouch />
            <CreatedBy />
        </>
    )
}

export default Team