import PrimaryButton from "../../../components/common/PrimaryButton/PrimaryButton";
import "./style.css";

const WhitePaperAnchors = () => {
  return (
    <section id="whitepaper-anchors">
      <div className="container">
        <span className="number">01</span>
        <a href="#whitepaper-introduction" className="anchor">
          <h3>Introduction</h3>
        </a>
        <span className="number">02</span>
        <a href="#whitepaper-nft" className="anchor">
          <h3>NFT</h3>
        </a>
        <span className="number">03</span>
        <a href="#whitepaper-technology" className="anchor">
          <h3>The technology behind NX7</h3>
        </a>
        <span className="number">04</span>
        <div>
          <a href="#whitepaper-tokenomics" className="anchor">
            <h3>Tokenomics</h3>
          </a>
          <div className="options">
            <span>4.1</span>
            <a href="#monetary-policy">
              <h4>Monetary Policy</h4>
            </a>
            <span>4.2</span>
            <a href="#token-utilization">
              <h4>Token Utilization</h4>
            </a>
            <span>4.3</span>
            <a href="#token-distribution">
              <h4>Token Distribution</h4>
            </a>
            <span>4.4</span>
            <a href="#vesting-schedules">
              <h4>Vesting Schedules</h4>
            </a>
            <span>4.5</span>
            <a href="#staking-mechanism">
              <h4>Staking Mechanism</h4>
            </a>
          </div>
        </div>
        <span className="number">05</span>
        <a href="#whitepaper-conclusion" className="anchor">
          <h3>Conclusion</h3>
        </a>
        <span className="number">06</span>
        <a href="#whitepaper-faqs" className="anchor">
          <h3>FAQS</h3>
        </a>
      </div>
      <div className="container">
        <a
          className="primary-button"
          download={"Whitepaper-nftx7.pdf"}
          href="/assets/Whitepaper-nftx7.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Whitepaper PDF
        </a>
      </div>
    </section>
  );
};

export default WhitePaperAnchors;
