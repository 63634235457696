import { Contract } from "ethers";
import { useContractCall, useContractFunction } from "@usedapp/core";
import { useNetworkContracts } from "../../context/hooks/NetworkContracts";
import { Interface } from "ethers/lib/utils";

interface UseNX7StakingResult {
  state: any;
  send: (...args: any[]) => void;
  events: any[] | undefined;
  resetState: () => void;
}

const useNX7Staking = (methodName: string): UseNX7StakingResult => {
  const { NX7Staking } = useNetworkContracts();
  const { state, send, events, resetState } = useContractFunction(
    NX7Staking?.contract as any,
    methodName,
    {}
  );
  return { state, send, events, resetState };
};

const ReadNX7Staking = (
  address: string | null | undefined,
  tokenId: Number
) => {
  const { NX7Staking } = useNetworkContracts();
  const stakingInfo = useContractCall({
    abi: NX7Staking?.interface as Interface,
    address: NX7Staking?.address as string,
    method: "getStaking",
    args: [address, tokenId],
  });
  return stakingInfo ? stakingInfo : {};
};

const GetTotalStaked = (address: string) => {
  const { NX7Staking } = useNetworkContracts();
  const totalStaked = useContractCall({
    abi: NX7Staking?.interface as Interface,
    address: NX7Staking?.address as string,
    method: "totalStakings",
    args: [address],
  });
  return totalStaked ? Number(totalStaked[0]) : null;
};

const GetUserStakedTokens = (address: string) => {
  const { NX7Staking } = useNetworkContracts();
  const stakedTokens = useContractCall({
    abi: NX7Staking?.interface as Interface,
    address: NX7Staking?.address as string,
    method: "getUserStakedTokens",
    args: [address],
  });
  return stakedTokens ? stakedTokens[0] : null;
};

export { useNX7Staking, GetTotalStaked, GetUserStakedTokens, ReadNX7Staking };
