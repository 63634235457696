import { useNX7Staking } from "../dapp";
import { useGasPrice } from "@usedapp/core";

const useStake = () => {
  const gas = useGasPrice();
  const {
    state: stakeState,
    events: stakeEvents,
    send,
  } = useNX7Staking("stake");
  const stake = (tokenId: number) => {
    send(tokenId, {
      gasPrice: gas,
      gasLimit: 200000,
    });
  };
  return {
    stakeState,
    stakeEvents,
    stake,
  };
};

export default useStake;
