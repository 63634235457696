import "./style.css";

export const TabsNFT = () => {
  return (
    <div className="nft-tabs">
      <article>
        <div className="tab-title">
          <h3>
            STEP 1: <br />
            Mint your NFT
            <img
              className="nftx-x"
              src="../assets/images/logo/nftx-x.png"
              alt="X"
            />
            7
          </h3>
        </div>
        <p className="text-justified">
          Buy your NFT
          <img
            className="nftx-x"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 for 100 USDT, and receive a unique NFT that represents your share of
          the fund.
        </p>
      </article>
      <article className="justify-center">
        <div className="tab-title">
          <h3>
            STEP 2: <br />
            Stake your NFT
            <img
              className="nftx-x"
              src="../assets/images/logo/nftx-x.png"
              alt="X"
            />
            7
          </h3>
        </div>
        <p className="text-justified">
          After purchasing your NFT
          <img
            className="nftx-x"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 collection, stake it to join the rewards program which runs on a
          seven-day cycle. <br />
          <br />
          At the beginning of every seven-day cycle a reward of 1 USDT per
          staked NFT
          <img
            className="nftx-x"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 worth of NX7 tokens, will be automatically added to your balance and
          ready to be collected and deposited intro your wallet.
          <br />
        </p>
        <img className="arrow" src="assets/images/nfts/arrow.png" alt="Arrow" />
      </article>
      <article className="justify-end">
        <div className="tab-title">
          <h3>
            STEP 3: <br />
            Trade your NFT
            <img
              className="nftx-x"
              src="../assets/images/logo/nftx-x.png"
              alt="X"
            />
            7
          </h3>
        </div>
        <p className="text-justified">
          NFT
          <img
            className="nftx-x"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 owner can sell their NFT on OpenSea for a royalty charge of 10%.
          <br />
          <br />
          Moreover, if the owner wishes to sell their NFT
          <img
            className="nftx-x"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 back to the collection creators, he needs to apply for it through
          the <a className="text-white" href="/buyback">buyback</a> tab to receive the minting price
          within 7 days.
        </p>
        <img className="arrow" src="assets/images/nfts/arrow.png" alt="Arrow" />
      </article>
    </div>
  );
};

export default TabsNFT;
