import { useState } from "react";
import { SocialDetail } from "./types";
import { icons } from "./data";
import "./style.css";

const SocialIcon = (Props: SocialDetail) => {
  const { title, url } = Props;
  const [iconPath, setIconPath] = useState(icons);
  const tag = url ? (
    <a target="_blank" href={url} className="social-bubble">
      <img src={iconPath.get(title)} alt={`${title}-svg`} />
    </a>
  ) : (
    <div className="social-bubble">
      <img src={iconPath.get(title)} alt={`${title}-svg`} />
    </div>
  );

  return <>{tag}</>;
};

export default SocialIcon;
