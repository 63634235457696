import { defaultData } from "./data";
import { useEffect, useState } from "react";

const TokenDistributionTable = () => {
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  useEffect(() => {
    defaultData.map(({ percentage, amount, value }) => {
      setTotalPercentage((prev) => prev + percentage);
      setTotalAmount((prev) => prev + amount);
      setTotalValue((prev) => prev + value);
    });
  }, []);

  return (
    <div className="token-distribution-container">
      <table>
        <thead>
          <tr>
            <th className="table-heading" colSpan={4}>Token Distribution</th>
          </tr>
          <tr className="border-block bottom-spacing">
            <th className="light-purple">Category</th>
            <th className="light-purple">Percentage</th>
            <th className="light-purple">Amount</th>
            <th className="light-purple">Value</th>
          </tr>
        </thead>
        <tbody>
          {defaultData.map(({ id, category, percentage, amount, value }) => {
            return (
              <tr key={id}>
                <td>{category}</td>
                <td className="light-purple">{percentage}%</td>
                <td>{amount}</td>
                <td>{value} USDT</td>
              </tr>
            );
          })}
          <tr className="border-block double-border-bottom">
            <td>Total</td>
            <td>{totalPercentage}%</td>
            <td>{totalAmount}</td>
            <td>{totalValue}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TokenDistributionTable;
