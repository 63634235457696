import { useNX7Nft } from "../dapp";
import { useGasPrice } from "@usedapp/core";
import Web3 from "web3";


const useSetApproval = () => {
  const gas = useGasPrice();
  const {
    state: setApprovalForAllState,
    events: setApprovalForAllEvents,
    send,
  } = useNX7Nft("setApprovalForAll");
  const setApprovalForAll = () => {
    send(process.env.REACT_APP_NX7STAKING_ADDRESS, true,{
      gasPrice: gas,
      gasLimit: 150000,
    });
  };
  return {
    setApprovalForAllState,
    setApprovalForAllEvents,
    setApprovalForAll,
  };
};

export default useSetApproval;
