import { defaultData } from "./data";

const VestingScheduleTable = () => {
  let teamArray = [];
  for (let i = 0; i < 60; i++) {
    teamArray[i] = {
      month: i,
      unlocking: defaultData.team_unlocking,
      circulating: defaultData.team_unlocking + defaultData.team_unlocking * i,
      share: defaultData.team_share + defaultData.team_share * i,
    };
  }
  let ecosystemArray = [];
  for (let i = 0; i < 48; i++) {
    ecosystemArray[i] = {
      month: i,
      unlocking: defaultData.ecosystem_unlocking,
      circulating:
        defaultData.ecosystem_unlocking + defaultData.ecosystem_unlocking * i,
      share: defaultData.ecosystem_share + defaultData.ecosystem_share * i,
    };
  }
  let advisoryArray = [];
  for (let i = 0; i < 5; i++) {
    advisoryArray[i] = {
      month: i,
      unlocking: defaultData.advisory_unlocking,
      circulating:
        defaultData.advisory_unlocking + defaultData.advisory_unlocking * i,
      share: defaultData.advisory_share + defaultData.advisory_share * i,
    };
  }
  let treasuryArray = [];
  for (let i = 0; i < 5; i++) {
    treasuryArray[i] = {
      month: i,
      unlocking: defaultData.treasury_unlocking,
      circulating:
        defaultData.treasury_unlocking + defaultData.treasury_unlocking * i,
      share: defaultData.treasury_share + defaultData.treasury_share * i,
    };
  }
  return (
    <>
      <div className="vesting-schedule scrollable-table">
        <table>
          <thead>
            <tr>
              <th className="table-heading" colSpan={4}>
                Team Tokens
              </th>
            </tr>
            <tr>
              <th className="light-purple">Month</th>
              <th className="light-purple">Unlocking schedule</th>
              <th className="light-purple">Addition to circulating supply</th>
              <th className="light-purple">Share of total supply</th>
            </tr>
          </thead>
          <tbody>
            {teamArray.map((item) => {
              return (
                <tr key={`team_${item.month}`}>
                  <td>{item.month + 1}</td>
                  <td>{item.unlocking}</td>
                  <td>{Math.round(item.circulating * 100) / 100}</td>
                  <td>{Math.round(item.share * 100) / 100}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="vesting-schedule scrollable-table">
        <table>
          <thead>
            <tr>
              <th className="table-heading" colSpan={4}>
                Ecosystem/Expense
              </th>
            </tr>
            <tr>
              <th className="light-purple">Month</th>
              <th className="light-purple">Unlocking schedule</th>
              <th className="light-purple">Addition to circulating supply</th>
              <th className="light-purple">Share of total supply</th>
            </tr>
          </thead>
          <tbody>
            {ecosystemArray.map((item) => {
              return (
                <tr key={`ecosystem_${item.month}`}>
                  <td>{item.month + 1}</td>
                  <td>{item.unlocking}</td>
                  <td>{Math.round(item.circulating * 100) / 100}</td>
                  <td>{Math.round(item.share * 100) / 100}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="vesting-schedule">
        <table>
          <thead>
            <tr>
              <th className="table-heading" colSpan={4}>
                Advisory
              </th>
            </tr>
            <tr>
              <th className="light-purple">Year</th>
              <th className="light-purple">Unlocking schedule</th>
              <th className="light-purple">Addition to circulating supply</th>
              <th className="light-purple">Share of total supply</th>
            </tr>
          </thead>
          <tbody>
            {advisoryArray.map((item) => {
              return (
                <tr key={`advisory_${item.month}`}>
                  <td>{item.month + 1}</td>
                  <td>{item.unlocking}</td>
                  <td>{Math.round(item.circulating * 100) / 100}</td>
                  <td>{Math.round(item.share * 100) / 100}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="vesting-schedule">
        <table>
          <thead>
            <tr>
              <th className="table-heading" colSpan={4}>
                Treasury and Reserve
              </th>
            </tr>
            <tr>
              <th className="light-purple">Year</th>
              <th className="light-purple">Unlocking schedule</th>
              <th className="light-purple">Addition to circulating supply</th>
              <th className="light-purple">Share of total supply</th>
            </tr>
          </thead>
          <tbody>
            {treasuryArray.map((item) => {
              return (
                <tr key={`treasury_${item.month}`}>
                  <td>{item.month + 1}</td>
                  <td>{item.unlocking}</td>
                  <td>{Math.round(item.circulating * 100) / 100}</td>
                  <td>{Math.round(item.share * 100) / 100}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default VestingScheduleTable;
