import React, { ReactNode, useEffect, useState } from "react";
import { useNetwork, ChainId } from "@usedapp/core";
import * as abis from "../../abis";
import { Contract, utils } from "ethers";
import config from "../../config";

interface INetworkContract {
    address: string;
    interface: utils.Interface;
    contract: Contract;
}

interface INetworkContractsContext {
    NX7token: INetworkContract | null;
    NX7Nft: INetworkContract | null;
    NX7Staking: INetworkContract | null;
}

interface NetworkContractsContextProviderProps {
    children: ReactNode;
}

export const NetworkContractsContext = React.createContext<INetworkContractsContext>({
    NX7token: null,
    NX7Nft: null,
    NX7Staking: null,
});

export const NetworkContractsContextProvider: React.FC<NetworkContractsContextProviderProps> = ({
    children,
}) => {
    const { network } = useNetwork();

    const [NX7token, setNX7Token] = useState<INetworkContract | null>(null);
    const [NX7Nft, setNX7Nft] = useState<INetworkContract | null>(null);
    const [NX7Staking, setNX7Staking] = useState<INetworkContract | null>(null);

    const nx7tokenInterface = new utils.Interface(abis.NX7Token.abi);
    const nx7nftInterface = new utils.Interface(abis.NX7Nft.abi);
    const nx7stakingInterface = new utils.Interface(abis.NX7Staking.abi);

    useEffect(() => {
        if (ChainId.Goerli) {
            const NX7TokenAddr = config.ContractsConfig[ChainId.Goerli].NX7TOKEN || "";
            const NX7TokenContract = new Contract(NX7TokenAddr, nx7tokenInterface);
            setNX7Token({
                address: NX7TokenAddr,
                interface: nx7tokenInterface,
                contract: NX7TokenContract,
            });

            const NX7NftAddr = config.ContractsConfig[ChainId.Goerli].NX7NFT || "";
            const NX7NftContract = new Contract(NX7NftAddr, nx7nftInterface);

            setNX7Nft({
                address: NX7NftAddr,
                interface: nx7nftInterface,
                contract: NX7NftContract,
            });

            const NX7StakingAddr = config.ContractsConfig[ChainId.Goerli].NX7STAKING || "";
            const NX7StakingContract = new Contract(NX7StakingAddr, nx7stakingInterface);

            setNX7Staking({
                address: NX7StakingAddr,
                interface: nx7stakingInterface,
                contract: NX7StakingContract,
            });
        }
    }, [network]);

    return (
        <NetworkContractsContext.Provider
            value={{
                NX7token,
                NX7Nft,
                NX7Staking,
            }}>
            {children}
        </NetworkContractsContext.Provider>
    );
};
