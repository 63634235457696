import "./style.css";
import { useState } from "react";
import PrimaryButton from "../../../components/common/PrimaryButton/PrimaryButton";

import ImportNFTAddress from "../../../components/common/ImportNFTAddress/ImportNFTAddress";
import * as abis from "../../../abis";
import { usePrepareContractWrite, useContractWrite, useAccount } from "wagmi";

const Mint = () => {
  const { address } = useAccount();
  const [counter, setCounter] = useState(0);
  const { config: approveConfig } = usePrepareContractWrite({
    address: "0xA64eBDc45647FC69547e7bA08Ee045Eec7470f59" as any,
    abi: abis.ERC20 as any,
    functionName: "approve",
    args: ["0x12DB4b58e05a53A9A9aa34C3748A7DeC4817B616", 1000000],
  });

  const { write: approveWrite } = useContractWrite(approveConfig);

  const { config: mintConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_NX7NFT_ADDRESS! as any,
    abi: abis.NX7Nft.abi as any,
    functionName: "publicMint",
    args: [counter],
  });
  const { write: mintWrite } = useContractWrite(mintConfig);

  const approve = async () => {
    await approveWrite?.();
  };

  const mint = async () => {
    await mintWrite?.();
  };
  //handel increament counter use previouse state
  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  //handel decreament counter use previouse state and stop at 0

  const handleDecrement = () => {
    if (counter > 0) {
      setCounter(counter - 1);
    }
  };

  return (
    <section id="mint-nft" className="container page-section">
      <h2 className="section-heading">
        Mint NFT
        <img
          className="nftx-x"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7
      </h2>
      <div className="mint-nft">
        <img
        className="mint-image"
          src="/assets/images/nfts/NFT-GIF.gif"
          alt="NFT"
        />
        <div className="mint-content">
          <h3 className="secondary-heading">
            THE NFT
            <img
              className="nftx-x"
              src="../assets/images/logo/nftx-x.png"
              alt="X"
            />
            7 TICKET
          </h3>
          <p className="text-justified">
            NFT
            <img
              className="nftx-x"
              src="../assets/images/logo/nftx-x.png"
              alt="X"
            />
            7 is an NFT collection that serves as proof of fractional debt. The
            project aims to invest the cashflow generated from mint sell-outs in
            scalp trading and pay rewards to NFT
            <img
              className="nftx-x"
              src="../assets/images/logo/nftx-x.png"
              alt="X"
            />
            7 holders in the form of NX7 tokens, which have a fixed reward
            value. The token has a deflationary mechanism and a built-in volume
            that serves as the cashflow coming from reward payments.
          </p>
          <div className="nft-value">
            <img src="../assets/images/logo/svg-nft-logo.svg" alt="Logo" />
            <h3 className="secondary-heading">
              100
              <span className="light-purple-text fs-small">&nbsp; USDT</span>
            </h3>
          </div>
          {/* create a counter input with plus and minus button */}
          <div className="counter">
            <div className="counter-actions">
              <PrimaryButton
                text="Approve"
                buttonClass="primary-button"
                onClick={approve}
              />
              <PrimaryButton
                text="Mint"
                buttonClass="primary-button"
                onClick={mint}
              />
            </div>
            <div className="counter-display">
              <div className="nftx">
                <p>NFTX7</p>
              </div>
              <div className="counter-buttons">
                <button
                  type="button"
                  onClick={handleDecrement}
                  className="decrement-button"
                >
                  -
                </button>
                <span>{counter}</span>
                <button
                  type="button"
                  onClick={handleIncrement}
                  className="increment-button"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          {/* import nft address */}
          <ImportNFTAddress />
        </div>
      </div>
    </section>
  );
};

export default Mint;