import { useNX7Staking } from "../dapp";
import { useGasPrice } from "@usedapp/core";


const useUnstake = () => {
  const gas = useGasPrice();
  const {
    state: unstakeState,
    events: unstakeEvents,
    send,
  } = useNX7Staking("unstake");
  const unstake = (tokenId: number) => {
    send(tokenId ,{
      gasPrice: gas,
      gasLimit: 200000,
    });
  };
  return {
    unstakeState,
    unstakeEvents,
    unstake,
  };
};

export default useUnstake;
