import "./style.css";
import { GetTotalSupply } from "../../../hooks/dapp/useNX7Nft";

const OurCollection = () => {
 // const totalSupply = GetTotalSupply();
  return (
    <section id="our-collection-banner" className="container">
      <h2>
        NFT
        <img
          className="nftx-x"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 Collection
      </h2>
      <div className="minted-collection">
        <h4>minted</h4>
        <h1>
          <span className="text-purple">0</span>/10,000
        </h1>
      </div>
    </section>
  );
};

export default OurCollection;
