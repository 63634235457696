import SocialIcon from "../../../components/common/SocialIcon/SocialIcon";
import "./style.css";

const GetInTouch = () => {
  return (
    <section id="team-touch" className="page-section">
      <h2 className="section-heading">Get In Touch</h2>
      <h4>
        We're on Discord!
        <br />
        Come say hi and ask us questions.
      </h4>
      <a target="_blank" href="https://www.discord.com">
        <SocialIcon title="Discord" />
        <span>Join us</span>
      </a>
    </section>
  );
};

export default GetInTouch;
