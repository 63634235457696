import CopySvg from "../../components/common/ImportNFTAddress/CopySvg";
import "./style.css";
import { useAccount } from "wagmi";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import PrimaryButton from "../../components/common/PrimaryButton/PrimaryButton";
import * as abis from "../../abis";

const Buyback = () => {
  const { address } = useAccount();
  const { config: stakeConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_NX7NFT_ADDRESS! as any,
    abi: abis.NX7Staking.abi as any,
    functionName: "buyBack",
    args: [address, "0x7a7e595b911a7b344F64c5AA4b85D96aF6b493A9", 7],
  });
  const { data: stakeData, write: stakeWrite } = useContractWrite(stakeConfig);
  const tokenValue = "0x730dd2594a072b6f73a0c1a33031d5f2f4f95f8b";

  return (
    <section id="buyback-nftx">
      <div className="banner-container">
        <img
          src="/assets/images/pages/buyback/banner-buyback.jpg"
          alt="Banner Image"
        />
        <div className="button-container">
          <PrimaryButton text="Buy Back" onClick={() => stakeWrite?.()} />
        </div>
      </div>
      <div className="container section-margin">
        {/* <h2 className='section-heading'>
                    BuyBack NFT
                    <img className='nftx-x' src='../assets/images/logo/nftx-x.png' alt='X' />7
                </h2> */}
        {/* <p className='text-center'>
                    As founders of NFT
                    <img className='nftx-x' src='../assets/images/logo/nftx-x.png' alt='X' />7 we
                    are committed to buying back any NFT
                    <img className='nftx-x' src='../assets/images/logo/nftx-x.png' alt='X' />7
                    bought by our community.{" "}
                </p>
            <p className='text-center'> To request a buyback please follow these steps.</p> */}
        <ul className="steps">
          <li>
            <h6>Step 1:</h6>
            <h4>Connect your wallet.</h4>
            {/* <img src="assets/images/pages/stake/step-1.jpeg" alt="Step 1" /> */}
          </li>
          <li>
            <h6>Step 2:</h6>
            <div className="address-container">
              <h4>Send your NFT to the following address</h4>
              <div className="address-container walletAddressLink">
                <span
                  onClick={() => navigator.clipboard.writeText(tokenValue)}
                  className="token-value"
                >
                  {tokenValue}
                  <CopySvg />
                </span>
              </div>
            </div>
          </li>
          <li>
            <h6>Step 3:</h6>
            <h4>Wait for funds to be deposited in your wallet within 7 days</h4>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Buyback;
