import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SocialIcon from "../../common/SocialIcon/SocialIcon";
import { defaultNavigationData, socialIcons } from "./data";
import "./style.css";

const Footer = () => {
  const navigate = useNavigate();
  const [navigationArray, setNavigationArray] = useState(defaultNavigationData);
  return (
    <section id="site-footer">
      <div className="container">
        <div className="footer-identity">
          <img src="/assets/images/logo/svg-logo-white.svg" alt="NX7 Logo" />
          <p>
            © 2023 NFT
            <img
              className="nftx-x"
              src="assets/images/logo/nftx-x.png"
              alt="X"
            />
            7. All right reserved.
          </p>
        </div>
        <nav className="footer-nav">
          <ul>
            {navigationArray.map((menuItem) => {
              return menuItem.path !== "no-path" ? (
                <li
                  className={
                    window.location.pathname == menuItem.path
                      ? "nav-link active"
                      : "nav-link"
                  }
                  key={menuItem.id}
                  onClick={() => {
                    menuItem.external_link
                      ? (window.location.href = menuItem.path)
                      : navigate(menuItem.path);
                  }}
                >
                  {menuItem.title}
                </li>
              ) : (
                <li className="nav-text" key={menuItem.id}>
                  <div className="footer-community">
                    <p>{menuItem.title}</p>
                    <div className="social-icons">
                      {socialIcons.map((icon) => {
                        return (
                          <SocialIcon
                            key={icon.id}
                            title={icon.title}
                            url={icon.url}
                          />
                        );
                      })}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default Footer;
