import "./style.css";
import { ButtonProps } from "./types";
const PrimaryButton = ({ text, buttonClass, onClick }: ButtonProps) => {
  return (
    <button
      type="button"
      className={
        buttonClass === "secondary"
          ? "secondary-button"
          : buttonClass === "animated"
          ? "animated primary-button"
          : "primary-button"
      }
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
