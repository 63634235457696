import { useNX7Staking } from "../dapp";
import { useGasPrice } from "@usedapp/core";

const useClaim = () => {
  const gas = useGasPrice();
  const {
    state: claimState,
    events: claimEvents,
    send,
  } = useNX7Staking("claim");
  const claim = (tokenId: number) => {
    send(tokenId, {
      gasPrice: gas,
      gasLimit: 400000,
    });
  };
  return {
    claimState,
    claimEvents,
    claim,
  };
};

export default useClaim;
