import { useState } from "react";
import { Contract } from "ethers";
import { useContractCall, useContractFunction } from "@usedapp/core";
import { useNetworkContracts } from "../../context/hooks/NetworkContracts";
import { Interface } from "ethers/lib/utils";
import Web3 from "web3";
import * as abis from "../../abis";
import { ChainId } from "@usedapp/core";
import config from "../../config";

const web3 = new Web3(Web3.givenProvider || process.env.REACT_APP_RPC_URL!);
const contract = new web3.eth.Contract(
    abis.NX7Nft.abi as any,
    config.ContractsConfig[ChainId.Goerli].NX7NFT
);

interface UseNX7NftResult {
    state: any;
    send: (...args: any[]) => void;
    events: any[] | undefined;
    resetState: () => void;
}

const dummyInterface = new Interface([]);

const useNX7Nft = (methodName: string): UseNX7NftResult => {
    const { NX7Nft } = useNetworkContracts();
    const { state, send, events, resetState } = useContractFunction(
        NX7Nft?.contract as any,
        methodName,
        {}
    );
    return { state, send, events, resetState };
};

const GetTotalSupply = () => {
    const [supply, setSupply] = useState<string | null>(null);
    const totalSupply = contract.methods
        .totalSupply()
        .call()
        .then((res: string) => {
            setSupply(res);
        });
    return supply ? supply : null;
};

const GetAmountToPay = () => {
    const [amount, setAmount] = useState<string | null>(null);
    const amountToPay = contract.methods
        .cost()
        .call()
        .then((res: string) => {
            setAmount(res);
        });
    return amount ? amount : null;
};

const GetApprovalForAll = (address: string) => {
    const { NX7Nft } = useNetworkContracts();
    const isApprovedForAll = useContractCall({
        abi: NX7Nft?.interface as Interface,
        address: NX7Nft?.address as string,
        method: "isApprovedForAll",
        args: [address, process.env.REACT_APP_NX7STAKING_ADDRESS],
    });

    return isApprovedForAll ? isApprovedForAll[0] : null;
};

export { useNX7Nft, GetTotalSupply, GetAmountToPay, GetApprovalForAll };
