import "./style.css";
import { useEffect, useState } from "react";
import PrimaryButton from "../../../components/common/PrimaryButton/PrimaryButton";
import useStake from "../../../hooks/ethCalls/useStake";
import useSetApproval from "../../../hooks/ethCalls/useSetApproval";
import useClaim from "../../../hooks/ethCalls/useClaim";
import useUnstake from "../../../hooks/ethCalls/useUnstake";
import { GetApprovalForAll, GetTotalStaked, GetUserStakedTokens } from "../../../hooks/dapp";
import { useEthers } from "@usedapp/core";
import { useContractWrite, usePrepareContractWrite, useContractRead } from "wagmi";
import * as abis from "../../../abis";
import { useAccount } from "wagmi";

const Stake = () => {
    const { address } = useAccount();
    const [tokenSelected, setSelectedTokens] = useState<BigInt>();

    const {
        data: tokens,
        error,
        isError,
        isLoading,
    } = useContractRead({
        address: process.env.REACT_APP_NX7NFT_ADDRESS! as any,
        abi: abis.NX7Nft.abi as any,
        functionName: "walletOfOwner",
        args: [address],
    });

    console.log("read data", tokens, error, isLoading);

    const { config: stakeConfig } = usePrepareContractWrite({
        address: process.env.REACT_APP_NX7STAKING_ADDRESS! as any,
        abi: abis.StakingA as any,
        functionName: "stakeBatch",
        args: [tokens],
    });
    const { data: stakeData, write: stakeWrite } = useContractWrite(stakeConfig);

    const { config: stake1Config } = usePrepareContractWrite({
        address: process.env.REACT_APP_NX7STAKING_ADDRESS! as any,
        abi: abis.StakingA as any,
        functionName: "stake",
        args: [tokenSelected],
    });

    const { data: stake1Data, write: stake1Write } = useContractWrite(stake1Config);

    const { config: approveConfig } = usePrepareContractWrite({
        address: "0xA64eBDc45647FC69547e7bA08Ee045Eec7470f59" as any,
        abi: abis.ERC20 as any,
        functionName: "approve",
        // args: ["0x12DB4b58e05a53A9A9aa34C3748A7DeC4817B616", 1000000],
    });

    const { write: approveWrite } = useContractWrite(approveConfig);

    const { config: claimConfig } = usePrepareContractWrite({
        address: process.env.REACT_APP_NX7STAKING_ADDRESS! as any,
        abi: abis.StakingA as any,
        functionName: "claimReward",
        args: [address],
    });

    const { write: claimWrite } = useContractWrite(claimConfig);

    const [tokenIds, setTokenIds] = useState<number[]>([]);
    const [stakedTokenIds, setStakedTokenIds] = useState<number[]>([]);

    //Georgio Added from here
    const [collecting, setCollecting] = useState(false);
    const [totalPendingRewards, setTotalPendingRewards] = useState(0);
    const pendingRewardsPerNFT = 1; // $1
    const rewardInterval = 5; // minutes
    const [lastClaimTimestamp, setLastClaimTimestamp] = useState(Date.now());
    const [countdown, setCountdown] = useState(rewardInterval * 60); // Initial countdown value in seconds

    const calculatePendingRewards = (stakedNFTCount: number, elapsedTime: number) => {
        const elapsedMinutes = Math.floor(elapsedTime / (1000 * 60)); // Convert elapsed time to minutes
        const pendingRewards =
            (stakedNFTCount * elapsedMinutes * pendingRewardsPerNFT) / rewardInterval;
        return pendingRewards;
    };

    //Georgio Added to here

    // Show user his tokenIds in a dropdown and let him choose one to stake
    // Remove the line below and replace the tokenId with the one chosen by the user
    const tokenId = tokenIds[0];
    const { account, activateBrowserWallet } = useEthers();

    const { stake, stakeState } = useStake();
    const { setApprovalForAll, setApprovalForAllState } = useSetApproval();
    const { claim, claimState } = useClaim();
    const { unstake, unstakeState } = useUnstake();

    const approved = GetApprovalForAll(account as string);
    const totalStaked = GetTotalStaked(account as string);
    const stakedTokens = GetUserStakedTokens(account as string);

    const connect = () => {
        activateBrowserWallet();
        //handleChangeNetwork();
    };

    const stakeFlow = async () => {
        if (account) {
            if (approved) {
                stake(tokenId);
            } else {
                setApprovalForAll();
            }
        } else {
            connect();
        }
    };

    const claimFlow = async () => {
        claimWrite?.();
    };
    const countdownTimer = () => {
        if (countdown === 0) {
            // Reset countdown to rewardInterval * 60 seconds when it reaches 0
            setCountdown(rewardInterval * 60);
        } else {
            // Decrement countdown by 1 second
            setCountdown((prevCountdown) => prevCountdown - 1);
        }
    };

    const unstakeFlow = async (stakedItemId: number) => {
        if (account) {
            unstake(stakedItemId);
        } else {
            connect();
        }
    };

    return (
        <section id='stake-nft' className='container page-section'>
            <div className='section-heading-container'>
                <div className='flex'>
                    <h2 className='section-heading'>
                        Stake NFT
                        <img className='nftx-x' src='../assets/images/logo/nftx-x.png' alt='X' />7
                    </h2>
                </div>
            </div>
            <div className='flex stakeButtonNew'>
                <PrimaryButton text='Stake All' onClick={() => stakeWrite?.()} />
            </div>
            {/* {tokens?.map((item: any) => {
                return (
                    <div className='counter-actions'>
                        <div className='flex stakeButtonNew'>
                            <PrimaryButton
                                text={`approve ${item}`}
                                onClick={() => {
                                    approveWrite?.({});
                                }}
                            />
                        </div>
                        <div className='flex stakeButtonNew'>
                            <PrimaryButton
                                text={`stack ${item}`}
                                onClick={() => stake1Write?.arguments[item]?.()}
                            />
                        </div>
                    </div>
                );
            })} */}

            <div className='stake-boxes-container'>
                <div className='stake-box'>
                    <h3 className='box-heading'>
                        Staked NFT
                        <img className='nftx-x' src='../assets/images/logo/nftx-x.png' alt='X' />7
                    </h3>
                    <div className='button-container'>
                        <p className='nft-staked'>{account ? totalStaked : 0}</p>
                    </div>
                </div>
                <div className='stake-box'>
                    <h3 className='box-heading'>NX7 Earned</h3>
                    <div className='stake-inner-box'>
                        <p>Total due rewards</p>
                        <div>
                            <div className='amount-container'>
                                <div>
                                    <span className='price'>{totalPendingRewards}</span>
                                    <span>NX7</span>
                                </div>
                                <p className='purple-text'>~13 USDT</p>
                            </div>
                        </div>
                        <PrimaryButton
                            text={collecting ? "Collecting..." : "Collect All"}
                            onClick={claimFlow}
                        />
                    </div>
                </div>
                <div className='stake-box span-2'>
                    <div className='table-container'>
                        <div className='table-row headers'>
                            <span>
                                Staked NFT
                                <img
                                    className='nftx-x'
                                    src='../assets/images/logo/nftx-x.png'
                                    alt='X'
                                />
                                7 ref
                            </span>
                            <span>Time till next reward</span>
                            <span>Due reward</span>
                            <span>Actions</span>
                        </div>
                        {stakedTokenIds.map((stakedItem) => {
                            const pendingRewards = calculatePendingRewards(
                                1,
                                Date.now() - lastClaimTimestamp
                            ); // Calculate pending rewards for each staked NFT

                            const minutes = Math.floor(countdown / 60);
                            const seconds = countdown % 60;

                            return (
                                <div key={`staked_${stakedItem}`} className='table-row data-row'>
                                    <p>NFT #{stakedItem}</p>
                                    <p>{`${minutes}:${seconds.toString().padStart(2, "0")}`}</p>{" "}
                                    {/* Display the countdown timer */}
                                    <p>{pendingRewards} USDT</p> {/* Display the pending rewards */}
                                    <PrimaryButton
                                        text='Unstake'
                                        onClick={() => unstakeFlow(stakedItem)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Stake;
