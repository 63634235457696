export const icons = new Map([
    ['Telegram', '/assets/social/svg-telegram.svg'],
    ['Discord', '/assets/social/svg-discord.svg'],
    ['Twitter', '/assets/social/svg-twitter.svg'],
    ['Instagram', '/assets/social/svg-instagram.svg'],
]);

export const socials = {
    'Telegram': {
        //TODO Fix Data
    }
}