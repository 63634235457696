import "./style.css";

const HowToMint = () => {
  return (
    <section id="how-to-mint" className="page-section">
      <div className="container">
        <h2 className="section-heading">How To Mint</h2>
        <ul>
          <li>
            <h6>Step 1:</h6>
            <h4>Connect your wallet.</h4>
            <img src="assets/images/pages/mint/step-1.jpg" alt="Step 1" />
          </li>
          <li>
            <h6>Step 2:</h6>
            <h4>
             Approve the contract spending amount.
             </h4>
            <img src="assets/images/pages/mint/step-2.jpg" alt="Step 2" />
          </li>
          <li>
            <h6>Step 3:</h6>
            <h4>Choose the number of NFT<img
                className="nftx-x"
                src="../assets/images/logo/nftx-x.png"
                alt="X"
              />7 to be minted.</h4>
            <img src="assets/images/pages/mint/step-3.jpg" alt="Step 3" />
          </li>
          <li>
            <h6>Step 4:</h6>
            <h4>Click on view blockchain explorer.</h4>
            <img src="assets/images/pages/mint/step-4.jpg" alt="Step 4" />
          </li>
          <li>
            <h6>Step 5:</h6>
            <h4>
              Copy the NFT
              <img
                className="nftx-x"
                src="../assets/images/logo/nftx-x.png"
                alt="X"
              />
              7 Address & ID.
            </h4>
            <img src="assets/images/pages/mint/step-5.jpg" alt="Step 5" />
          </li>
          <li>
            <h6>Step 6:</h6>
            <h4>
              Import your NFT
              <img
                className="nftx-x"
                src="../assets/images/logo/nftx-x.png"
                alt="X"
              />
              7.
            </h4>
            <img src="assets/images/pages/mint/step-6.jpg" alt="Step 6" />
          </li>
          <li>
            <h6>Step 7:</h6>
            <h4>
              Paste the NFT
              <img
                className="nftx-x"
                src="../assets/images/logo/nftx-x.png"
                alt="X"
              />
              7 Address & ID.
            </h4>
            <img src="assets/images/pages/mint/step-7.jpg" alt="Step 7" />
          </li>
          <li>
            <h6>Step 8:</h6>
            <h4>Click on refresh and your NFT<img
                className="nftx-x"
                src="../assets/images/logo/nftx-x.png"
                alt="X"
              />7 will appear.</h4>
            <img src="assets/images/pages/mint/step-8.jpg" alt="Step 8" />
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HowToMint;
