import HowToMint from "./HowToMint/HowToMint";
import Mint from "./Mint/Mint";
import OurCollection from "./OurCollection/OurCollection";

const MyNft = () => {
  return (
    <>
      <OurCollection />
      <Mint />
      <HowToMint />
    </>
  );
};

export default MyNft;
