import React, { useContext } from "react";
import { NetworkContractsContext } from "../providers";

export const useNetworkContracts = () => {
  const context = useContext(NetworkContractsContext);
  if (context === undefined) {
    throw new Error(
      "useNetworkContracts must be used within a NetworkContractsContext"
    );
  }
  const { NX7token, NX7Nft, NX7Staking } = context;
  return {
    NX7token,
    NX7Nft,
    NX7Staking,
  };
};