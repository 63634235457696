import DappConfig from "./dapp";
import ContractsConfig from "./contracts";

const config = {
    DappConfig: { ...DappConfig },
    ContractsConfig: { ...ContractsConfig },
    Ethscan: "Goerli.polygonscan.com/tx/",
    Alchemy: process.env.REACT_APP_RPC_URL,
};
export default config;
