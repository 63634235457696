import { getChainById, ChainId } from "@usedapp/core";
import { ethers } from "ethers";

const DappConfig = {
    readOnlyChainId: ChainId.Goerli,
    readOnlyUrls: {
        [ChainId.Goerli]: ethers.getDefaultProvider(process.env.REACT_APP_RPC_URL),
    },
    networks: [getChainById(ChainId.Goerli)],
    autoConnect: false,
};

export default DappConfig;
