import OurStory from "./OurStory/OurStory";
import JoinBanner from "./JoinBanner/JoinBanner";
import TabsNFT from "../../components/common/TabsNFT/TabNFT";

const About = () => {
  return (
    <>
      <JoinBanner />
      <OurStory />
      <section id="about-choose" className="container page-section">
        <h2 className="section-heading">How it works</h2>
        <TabsNFT />
      </section>
    </>
  );
};

export default About;
