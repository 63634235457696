import "./style.css";

const Technology = () => {
  return (
    <section className="section-color">
 <section id="technology-behind-nx7" className="container">
      <div className="">
        <h2 className="section-heading align-left">
          The Technology behind NX7 Token
        </h2>
        <p className="text-justified">
          Using NFT
          <img
            className="nftx-x"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7s allows for a crowd-funded method of starting a trading portfolio.
          The speed and high liquidity of NFT
          <img
            className="nftx-x"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7s make them an ideal choice for this project. Our deflationary token
          model ensures that the value of the token can appreciate, which may
          lead to an increase in the value of the rewards.
        </p>
      </div>
      <img
        className="coins"
        src="assets/images/pages/whitepaper/nx7-technology.png"
        alt="Technology Image"
      />
    </section>

    </section>
   
  );
};

export default Technology;
